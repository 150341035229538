import { PatientBalloonPlacement } from '@allurion/domain';
import useSWR from 'swr';

import { buildUrl, InsightsApi } from 'src/api/Api';

const PATIENT_BALLOON_PLACEMENT = (patientId: string) =>
  `/patients/${patientId}/balloon-placements`;

export function usePatientBalloonPlacements(
  patientId: string,
  { isEnabled }: { isEnabled: boolean }
) {
  const url = patientId && isEnabled && buildUrl(PATIENT_BALLOON_PLACEMENT(patientId));
  const { data, error, isValidating, mutate } = useSWR(
    url,
    (_url: string) =>
      InsightsApi.get<{ placements: PatientBalloonPlacement[] }>(_url).then(
        (res) => res.placements
      ),
    {
      revalidateOnFocus: false,
      suspense: true,
    }
  );

  function createBalloonPlacement(body: Partial<PatientBalloonPlacement>) {
    return InsightsApi.post(buildUrl(PATIENT_BALLOON_PLACEMENT(patientId)), body).then((res) => {
      mutate();

      return res;
    });
  }

  return {
    isLoading: isValidating,
    placements: data ?? [],
    error,
    createBalloonPlacement,
  };
}
