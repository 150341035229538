import { ClinicFeatures } from '@allurion/domain';
import { Card, FormGroup, InputSelector, TextInput } from '@allurion/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { z } from 'zod';

import { TrackedPageHeader } from 'src/analytics/TrackedUI';
import { Seo } from 'src/components/Seo';
import { Loader } from 'src/components/ui/Loader';
import { toastError, toastSuccess } from 'src/components/ui/toasts';
import { useAppNavigate } from 'src/hooks/useAppNavigate';
import { useCurrentProvider } from 'src/hooks/useCurrentProvider';
import { useCurrentUser } from 'src/hooks/useCurrentUser';
import { useUserClinics } from 'src/hooks/useUserClinics';
import { useUserClinicsSettings } from 'src/hooks/useUserClinicsSettings';
import globalMessages from 'src/messages/global.messages';
import { Logger } from 'src/services/Logger';

import messages from './ProfilePage.messages';

import styles from './ProfilePage.module.scss';

type ProfileFormData = {
  firstName: string;
  lastName: string;
  unitsPreference: string;
  notificationsEnabled: boolean;
  notificationsFrequency: string;
};

export default function ProfilePage() {
  const intl = useIntl();
  const { clinics, isLoading: isLoadingClinics } = useUserClinics();
  const { hasAccessToFeature } = useUserClinicsSettings();
  const { user, update } = useCurrentUser();
  const { weightUnit, reload } = useCurrentProvider();
  const { toClinicPage, toPreviousPage } = useAppNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const clinicHasEmailNotifications = hasAccessToFeature(ClinicFeatures.EmailNotifications);
  const clinicHasMessages = hasAccessToFeature(ClinicFeatures.Messages);
  const showEmailNotificationSettings = clinicHasMessages && clinicHasEmailNotifications;

  const ProfileSchema = z
    .object({
      firstName: z.string().nonempty(intl.formatMessage(globalMessages.required)),
      lastName: z.string().nonempty(intl.formatMessage(globalMessages.required)),
      unitsPreference: z.string().nonempty(intl.formatMessage(globalMessages.required)),
      notificationsEnabled: z.boolean(),
      notificationsFrequency: z.string().optional(),
    })
    .refine((data) => !data.notificationsEnabled || !!data.notificationsFrequency, {
      path: ['notificationsFrequency'],
      message: intl.formatMessage(globalMessages.required),
    });

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    watch,
  } = useForm<ProfileFormData>({
    resolver: zodResolver(ProfileSchema),
    defaultValues: {
      firstName: user.firstName ?? '',
      lastName: user.lastName ?? '',
      unitsPreference: weightUnit,
      notificationsEnabled: user.notificationsEnabled ?? false,
      notificationsFrequency: user.notificationsFrequency ?? '',
    },
  });

  const onSubmit = async ({
    firstName,
    lastName,
    unitsPreference,
    notificationsEnabled,
    notificationsFrequency,
  }: ProfileFormData) => {
    try {
      setIsLoading(true);

      await update({
        firstName,
        lastName,
        unitsPreference,
        notificationsEnabled,
        notificationsFrequency: notificationsEnabled ? notificationsFrequency : undefined,
      });
      await reload();
      toastSuccess(intl.formatMessage(messages.updateProfileSuccess));
      setIsLoading(false);

      toClinicPage();
    } catch (error) {
      Logger.captureException(error);
      toastError(intl.formatMessage(messages.updateProfileFailure));
      setIsLoading(false);
    }
  };

  const weightUnitOptions: { label: string; value: string }[] = [
    { label: 'Kg', value: 'kg' },
    { label: 'Lb', value: 'lb' },
  ];

  const messageNotificationsOptions = [
    {
      label: intl.formatMessage(messages.messageNotificationsOnceADay),
      description: intl.formatMessage(messages.messageNotificationsOnceADayDescription),
      value: 'once_day',
    },
    { label: intl.formatMessage(messages.messageNotificationsEveryTime), value: 'everytime' },
  ];

  const notificationsEnabled = watch('notificationsEnabled');

  return (
    <div className={styles.outerContainer}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Seo title="Profile" />
        <TrackedPageHeader
          title={intl.formatMessage(messages.title)}
          onNavButtonClick={() => toPreviousPage()}
          onNavButtonClickTrackLabel="go-back"
          button={{
            label: intl.formatMessage(messages.saveProfile),
            disabled: !isDirty,
            size: 'sm',
            trackLabel: 'submit-profile-form',
            type: 'submit',
          }}
        />

        <div className={styles.container}>
          <div className={styles.column}>
            <Card title={'Personal identification'}>
              <div className={styles.container}>
                <div className={styles.column}>
                  <FormGroup
                    label={intl.formatMessage(messages.firstName)}
                    error={errors.firstName?.message}
                  >
                    <TextInput {...register('firstName')} />
                  </FormGroup>
                </div>
                <div className={styles.column}>
                  <FormGroup
                    label={intl.formatMessage(messages.lastName)}
                    error={errors.lastName?.message}
                  >
                    <TextInput {...register('lastName')} />
                  </FormGroup>
                </div>
              </div>

              <div className={styles.container}>
                <div className={styles.column}>
                  <div className={styles.item} data-cy="email">
                    <label
                      className={styles.valueLabel}
                    >{`${intl.formatMessage(messages.email)}:`}</label>
                    <div className={styles.value}>{user?.email}</div>
                  </div>
                </div>
                <div className={styles.column}>
                  <Link className={styles.resetPasswordLink} to="/reset-password">
                    {intl.formatMessage(messages.resetPassword)}
                  </Link>
                </div>
              </div>
            </Card>
            <Card title={intl.formatMessage(messages.clinic)} data-cy="clinic">
              {clinics.map((clinic) => (
                <div className={styles.value} key={clinic.ID}>
                  {clinic?.post_title}
                </div>
              ))}
            </Card>
          </div>
          <div className={styles.column}>
            <Card title={intl.formatMessage(messages.units)} data-cy="units">
              <div style={{ display: 'flex', gap: '20px' }}>
                {weightUnitOptions.map(({ label, value }) => (
                  <InputSelector
                    {...register('unitsPreference')}
                    type="radio"
                    label={label}
                    key={value}
                    value={value}
                  />
                ))}
              </div>
            </Card>
            {showEmailNotificationSettings && (
              <Card title={intl.formatMessage(messages.messageNotifications)}>
                <div className={styles.item} data-cy="message-notifications">
                  <p>{`${intl.formatMessage(messages.messageNotificationsDescription)}`}</p>
                  <InputSelector
                    {...register('notificationsEnabled')}
                    type="checkbox"
                    label={intl.formatMessage(messages.messageNotificationsToggleLabel)}
                  />

                  {notificationsEnabled && (
                    <div
                      className={styles.item}
                      data-cy="message-notifications-frequency"
                      style={{ marginTop: '20px' }}
                    >
                      <FormGroup
                        label={intl.formatMessage(messages.messageNotificationsNotifyMe)}
                        error={errors.notificationsFrequency?.message}
                      >
                        {messageNotificationsOptions.map(({ label, value }) => (
                          <InputSelector
                            {...register('notificationsFrequency')}
                            type="radio"
                            label={label}
                            key={value}
                            value={value}
                          />
                        ))}
                      </FormGroup>
                    </div>
                  )}
                </div>
              </Card>
            )}
          </div>
        </div>
      </form>
      <Loader isLoading={isLoading || isLoadingClinics} />
    </div>
  );
}
