import { PatientCoachIrisHistory } from '@allurion/domain';
import { useMemo } from 'react';
import useSWRInfinite from 'swr/infinite';

import { buildUrl, InsightsApi } from 'src/api/Api';

export const COACH_IRIS_HISTORY = (patientId: string) =>
  `/patients/${patientId}/coach-iris-history`;

const PAGE_SIZE = 50;

export function useCoachIrisHistory(patientId?: string) {
  const {
    data: result,
    error,
    isValidating,
    isLoading,
    setSize,
    size,
  } = useSWRInfinite(
    (pageIndex, previousPageData) => {
      const url =
        patientId &&
        buildUrl(COACH_IRIS_HISTORY(patientId), {
          pageSize: PAGE_SIZE,
          lastEvaluatedKey: previousPageData?.lastEvaluatedKey
            ? encodeURIComponent(previousPageData.lastEvaluatedKey)
            : undefined,
        });

      if (pageIndex > 0 && !previousPageData.lastEvaluatedKey) return null;

      return url;
    },
    (_url: string) =>
      InsightsApi.get<{ data: PatientCoachIrisHistory[]; lastEvaluatedKey: string | undefined }>(
        _url
      ).then(({ data, lastEvaluatedKey }) => {
        return {
          data: data.reverse(),
          lastEvaluatedKey,
        };
      }),
    {
      revalidateOnFocus: false,
      suspense: true,
      // revalidateAll: false,
      // revalidateFirstPage: false,
      // parallel: true,
    }
  );

  const isLoadingMore = Boolean(
    isLoading || (size > 0 && result && typeof result[size - 1] === 'undefined')
  );
  const isEmpty = result?.[0]?.data.length === 0;
  const isReachingEnd = Boolean(
    isEmpty || (result && result[result.length - 1]?.data.length < PAGE_SIZE)
  );
  const messages = useMemo(() => result?.reverse()?.flatMap(({ data }) => data) ?? [], [result]);

  return {
    isLoading: isValidating,
    isLoadingMore,
    isReachingEnd,
    messages,
    error,
    loadMore: () => {
      setSize((size) => size + 1);
    },
  };
}
