import { Card, InfoIcon, PlusIcon, useDialog } from '@allurion/ui';
import { toLocalizedDate } from '@allurion/utils';
import { ComponentProps } from 'react';
import { useIntl } from 'react-intl';

import { TrackedEmptyState } from 'src/analytics/TrackedUI';
import { usePatientBalloonPlacements } from 'src/hooks/usePatientBalloonPlacements';

import { PatientBalloonPlacementForm } from './PatientBalloonPlacementForm';

import styles from './PatientBalloonPlacement.module.scss';

type Props = {
  patientId: string;
  clinicId: string;
};

export function PatientBalloonPlacement({ patientId, clinicId }: Props) {
  const intl = useIntl();
  const { placements, createBalloonPlacement } = usePatientBalloonPlacements(patientId, {
    isEnabled: true,
  });

  const { openDialog, Dialog } = useDialog<ComponentProps<typeof PatientBalloonPlacementForm>>({
    content: ({ close, ...contentProps }) => {
      return (
        <div onClick={(e) => e.stopPropagation()}>
          <PatientBalloonPlacementForm
            {...contentProps}
            onSave={async (placement) => {
              await createBalloonPlacement(placement);

              close?.();
            }}
            onCancel={() => {
              close?.();
            }}
          />
        </div>
      );
    },
  });

  if (!placements || placements.length === 0) {
    return (
      <>
        <Card>
          <TrackedEmptyState
            title={intl.formatMessage({
              id: 'patient-balloon-tracking.empty-state.title',
              defaultMessage: 'No balloon registered for this patient yet',
            })}
            subtitle={intl.formatMessage({
              id: 'patient-balloon-tracking.empty-state.subtitle',
              defaultMessage:
                'You must register a balloon for this patient to be correctly recorded',
            })}
            icon={<InfoIcon />}
            trackedLabel="no-patient-balloon-tracking-found"
            buttons={[
              {
                label: intl.formatMessage({
                  id: 'patient-balloon-tracking.empty-state.button',
                  defaultMessage: 'Register balloon',
                }),
                onClick: onTrackBalloon,
              },
            ]}
          />
        </Card>
        <Dialog />
      </>
    );
  }

  return (
    <>
      <Card
        title={intl.formatMessage({
          id: 'patient-balloon-tracking.title',
          defaultMessage: 'Registered balloons',
        })}
        iconButtons={[
          {
            onClick: onTrackBalloon,
            icon: <PlusIcon />,
            tooltip: intl.formatMessage({
              id: 'patient-balloon-tracking.add-balloon',
              defaultMessage: 'Register a balloon',
            }),
          },
        ]}
      >
        {placements.map((placement, index) => (
          <div key={index} className={styles.trackedBalloon}>
            <div className={styles.title}>
              <div>
                <span>
                  {intl.formatMessage({
                    id: 'patient-balloon-tracking.serial-number',
                    defaultMessage: 'Serial number',
                  })}
                </span>
                :&nbsp;
                <span>{placement.serialNumber}</span>
              </div>
              <div>
                <span>
                  {intl.formatMessage({
                    id: 'patient-balloon-tracking.manufactoring-lot',
                    defaultMessage: 'Manufacturing lot',
                  })}
                </span>
                :&nbsp;
                <span>{placement.manufacturingLot}</span>
              </div>
            </div>
            <div className={styles.subtitle}>
              <span>
                {intl.formatMessage({
                  id: 'patient-balloon-tracking.placement-date',
                  defaultMessage: 'Placement date',
                })}
              </span>
              :&nbsp;
              <div className={styles.date}>
                {toLocalizedDate(placement.placementDate ?? placement.scanningDate, 'en')}
              </div>
            </div>
          </div>
        ))}
      </Card>
      <Dialog />
    </>
  );

  function onTrackBalloon() {
    openDialog({
      title: intl.formatMessage({
        id: 'patient-balloon-tracking.track-balloon',
        defaultMessage: 'Register balloon',
      }),
      contentProps: {
        patientId,
        clinicId,
        onSave: handleCreate,
        onCancel: () => {},
      },
    });
  }

  function handleCreate() {
    // create balloon
  }
}
