import { useIntl } from 'react-intl';

import { TrackedEmptyState } from 'src/analytics/TrackedUI';
import { InlineLoader } from 'src/components/ui/InlineLoader';
import { useCoachIrisSummary } from 'src/hooks/useCoachIrisSummary';
import { useLocale } from 'src/hooks/useLocale';

import styles from './CoachIrisSummary.module.scss';

export function CoachIrisSummary({ patientId }: { patientId: string }) {
  const { locale } = useLocale();
  const intl = useIntl();
  const { summary, isValidating } = useCoachIrisSummary(patientId, locale);

  const hasSummary =
    (summary?.topics?.length ?? 0) > 0 ||
    summary?.mood !== '' ||
    (summary?.recommendations?.length ?? 0) > 0;

  if (isValidating) {
    return <InlineLoader />;
  }

  if (!hasSummary || !summary) {
    return (
      <TrackedEmptyState
        trackedLabel={intl.formatMessage({
          id: 'coach-iris.no-summary-available',
          defaultMessage: 'No summary available.',
        })}
        title={intl.formatMessage({
          id: 'coach-iris.no-summary-available',
          defaultMessage: 'No summary available.',
        })}
        icon={undefined}
      />
    );
  }

  return (
    <div className={styles.summary}>
      <h2 className={styles.summaryTitle}>
        {intl.formatMessage({
          id: 'coach-iris.summary',
          defaultMessage: 'Summary',
        })}
      </h2>
      <div>
        <br />
        <div>
          <h3>
            {intl.formatMessage({
              id: 'coach-iris.topics',
              defaultMessage: 'Topics',
            })}
          </h3>
          <div>
            {summary.topics && summary.topics.filter((topic) => topic.trim() !== '').length > 0 ? (
              <ul>
                {summary.topics.map((topic, index) => (
                  <li key={index}>{topic}</li>
                ))}
              </ul>
            ) : (
              <TrackedEmptyState
                trackedLabel={intl.formatMessage({
                  id: 'coach-iris.no-topics-available',
                  defaultMessage: 'No topics available.',
                })}
                title={intl.formatMessage({
                  id: 'coach-iris.no-topics-available',
                  defaultMessage: 'No topics available.',
                })}
                icon={undefined}
              />
            )}
          </div>
        </div>
        <br />
        <div>
          <h3>
            {intl.formatMessage({
              id: 'coach-iris.mood',
              defaultMessage: 'Mood',
            })}
          </h3>
          <div>
            {summary.mood ? (
              <ul>
                <li>{summary.mood}</li>
              </ul>
            ) : (
              <TrackedEmptyState
                trackedLabel={intl.formatMessage({
                  id: 'coach-iris.no-mood-available',
                  defaultMessage: 'No mood available.',
                })}
                title={intl.formatMessage({
                  id: 'coach-iris.no-mood-available',
                  defaultMessage: 'No mood available.',
                })}
                icon={undefined}
              />
            )}
          </div>
        </div>
        <br />
        <div>
          <h3>
            {intl.formatMessage({
              id: 'coach-iris.recommendations',
              defaultMessage: 'Recommendations',
            })}
          </h3>
          <div>
            {summary.recommendations &&
            summary.recommendations.filter((recommendation) => recommendation.trim() !== '')
              .length > 0 ? (
              <ul>
                {summary.recommendations.map((recommendation, index) => (
                  <li key={index}>{recommendation}</li>
                ))}
              </ul>
            ) : (
              <TrackedEmptyState
                trackedLabel={intl.formatMessage({
                  id: 'coach-iris.no-recommendations-available',
                  defaultMessage: 'No recommendations available.',
                })}
                title={intl.formatMessage({
                  id: 'coach-iris.no-recommendations-available',
                  defaultMessage: 'No recommendations available.',
                })}
                icon={undefined}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
