import useSWR from 'swr';

import { InsightsApi } from 'src/api/Api';
import { Provider } from 'src/domain/Provider';

export function useProvider(providerId?: string) {
  const { data, error, isValidating, mutate } = useSWR(
    providerId && `/providers/${providerId}`,
    (_url: string) => InsightsApi.get<Provider>(_url),
    {
      revalidateOnFocus: false,
      suspense: true,
    }
  );

  return { isLoading: isValidating, provider: data, error, reload: mutate };
}
