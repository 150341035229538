import {
  hasAllurionBalloonTreatment,
  isBalloonTrackingEnabled,
  isPatientGroupingEnabled,
} from '@allurion/domain';
import { useIntl } from 'react-intl';

import { useClinicSettings } from 'src/hooks/useClinicSettings';
import { useDevice } from 'src/hooks/useDevice';
import { usePatientBalloonPlacements } from 'src/hooks/usePatientBalloonPlacements';
import { usePatientOverview } from 'src/hooks/usePatientOverview';
import { usePatientProfile } from 'src/hooks/usePatientProfile';
import { usePatientTreatments } from 'src/hooks/usePatientTreatments';
import translations from 'src/messages/translations';
import env from 'src/utils/env';

import { PatientBalloonPlacement } from './PatientBalloonPlacement/PatientBalloonPlacement';
import { PatientProfileCard } from './PatientProfileCard';
import { PatientProgressOverviewCard } from './PatientProgressOverviewCard';
import { PatientProvidersCard } from './PatientProvidersCard/PatientProvidersCard';
import { PatientTbwlCard } from './PatientTbwlCard';
import { PatientTreamentsCard } from './PatientTreamentsCard/PatientTreamentsCard';

import styles from './PatientOverview.module.scss';

type Props = {
  patientId: string;
  clinicId: string;
  reloadData: () => void;
};

export function PatientOverview({ patientId, clinicId, reloadData }: Props) {
  const intl = useIntl();
  const { isDesktop } = useDevice();
  const { overviewData: patientOverviewData, isLoading: isLoadingPatientOverview } =
    usePatientOverview(patientId);
  const { settings } = useClinicSettings(clinicId);
  const { patient: patientInfo } = usePatientProfile(patientId);
  const { treatments, isLoading } = usePatientTreatments(patientId);
  const { placements } = usePatientBalloonPlacements(patientId, {
    isEnabled: isBalloonTrackingEnabled(settings),
  });
  const hasNoTreatments = treatments.length === 0 && !isLoading;
  const hasBalloonTreatment = hasAllurionBalloonTreatment(treatments);
  const hasBalloonPlacement = placements.length > 0;
  const showBalloonTrackingCard =
    isBalloonTrackingEnabled(settings) && hasBalloonTreatment && !env.IS_PROD;

  const cards = [
    <PatientProgressOverviewCard
      key="progress-overview"
      patientId={patientId}
      data={patientOverviewData}
      patient={patientInfo}
      settings={settings}
      reloadData={reloadData}
    />,
    <PatientTbwlCard isLoading={isLoadingPatientOverview} data={patientOverviewData} key="tbwl" />,
    <PatientProfileCard patient={patientInfo} key="profile" />,
    <PatientTreamentsCard
      key="treatments"
      patientId={patientId}
      clinicId={clinicId!}
      cardTitle={intl.formatMessage(translations.treatmentHistory)}
    />,
    isPatientGroupingEnabled(settings) && (
      <PatientProvidersCard patientId={patientId} clinicId={clinicId} key="providers" />
    ),
    showBalloonTrackingCard && (
      <PatientBalloonPlacement patientId={patientId} clinicId={clinicId} />
    ),
  ];

  /* move the treatments card up if hasNoTreatments */
  if (isDesktop && hasNoTreatments) {
    cards.splice(0, 0, cards.splice(3, 1)[0]);
  }

  // move balloon tracking card to beginning if hasBalloonTracking
  if (isDesktop && !hasBalloonPlacement && showBalloonTrackingCard) {
    cards.splice(0, 0, cards.splice(cards.length - 1, 1)[0]);
  }

  return (
    <div className={styles.container}>
      {isDesktop ? (
        cards
      ) : (
        <>
          <div className={styles.column}>
            {/* move the treatments card up if hasNoTreatments */}
            {!hasBalloonPlacement && cards[5]}
            {hasNoTreatments ? cards[3] : cards[0]}
            {hasNoTreatments ? cards[0] : cards[3]}
            {cards[4]}
            {hasBalloonPlacement && cards[5]}
          </div>
          <div className={styles.column}>
            {cards[1]}
            {cards[2]}
          </div>
        </>
      )}
    </div>
  );
}
