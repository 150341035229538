import {
  Article,
  ArticleCollection,
  canBeUsedByClinic,
  isAllurionBalloonTreatment,
  isCompatibleWithTreatmentSubcategories,
  isLibraryPlusContentEnabled,
} from '@allurion/domain';
import { Card, SearchIcon } from '@allurion/ui';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { TrackedEmptyState, TrackedSortByMenu } from 'src/analytics/TrackedUI';
import { useArticleCollections } from 'src/hooks/useArticleCollections';
import { useClinicSettings } from 'src/hooks/useClinicSettings';
import { usePatientContentSubscription } from 'src/hooks/useContentSubscription';
import { useDevice } from 'src/hooks/useDevice';
import { usePatientArticles } from 'src/hooks/usePatientArticles';
import { usePatientTreatments } from 'src/hooks/usePatientTreatments';
import { useTableSort } from 'src/ui/useTableSort';

import { ContentArticlesCards } from './ContentArticlesCards';
import { ContentArticlesTable } from './ContentArticlesTable';
import { ContentSubscription } from './ContentSubscription';

import styles from './PatientContentLibrary.module.scss';

type Props = {
  patientId: string;
  clinicId: string;
};

export function PatientContentLibrary({ patientId, clinicId }: Props) {
  const intl = useIntl();
  const { articleCollections } = useArticleCollections();
  const { articles, reload, isLoading } = usePatientArticles(patientId);
  const {
    subscriptions,
    reload: reloadSubscriptions,
    isLoading: isLoadingSubscriptions,
  } = usePatientContentSubscription(patientId);
  const { treatments } = usePatientTreatments(patientId);
  const { settings } = useClinicSettings(clinicId);
  const allowedTreatments = treatments.filter(
    ({ treatmentCategoryId }) =>
      isAllurionBalloonTreatment(treatmentCategoryId) || isLibraryPlusContentEnabled(settings)
  );
  const treatmentSubcategories = allowedTreatments.map(
    (treatment) => treatment.treatmentSubcategoryName
  );

  const articlesByCollection = useMemo(() => {
    const articlesByCollection = articles.reduce(
      (map, article) => ({
        ...map,
        [article.collectionId]: [...(map[article.collectionId] || []), article],
      }),
      {} as Record<string, typeof articles>
    );

    return articlesByCollection;
  }, [articles]);

  const availableCollections = articleCollections.filter(
    (collection) =>
      isCompatibleWithTreatmentSubcategories(collection, treatmentSubcategories) &&
      canBeUsedByClinic(collection, clinicId)
  );

  if (availableCollections.length === 0) {
    const missingTreatment = treatments.length === 0;
    const title = intl.formatMessage({
      id: 'content-library.empty-state.title',
      defaultMessage: 'No content available',
    });
    let subtitle: string;

    if (missingTreatment) {
      subtitle = intl.formatMessage({
        id: 'content-library.empty-state.no-treatment',
        defaultMessage: 'Please add a treatment to see articles for this patient.',
      });
    } else if (!isLibraryPlusContentEnabled(settings)) {
      subtitle = intl.formatMessage({
        id: 'content-library.empty-state.upgrade-vcs-plus',
        defaultMessage: 'Upgrade to VCS+ to access educational content for this patient.',
      });
    } else {
      const compatibleCollections = articleCollections.filter((collection) =>
        isCompatibleWithTreatmentSubcategories(collection, treatmentSubcategories)
      );

      if (compatibleCollections.length === 0) {
        subtitle = intl.formatMessage({
          id: 'content-library.empty-state.no-collection-treatment',
          defaultMessage: `We could not find any articles for this patient's treatment. Please contact help@allurion.com if this issue persists.`,
        });
      } else {
        subtitle = intl.formatMessage({
          id: 'content-library.empty-state.no-collection-clinic',
          defaultMessage: `This clinic doesn't have access to any articles. Please contact help@allurion.com if you think this is an error.`,
        });
      }
    }

    return (
      <div className={styles.container}>
        <Card>
          <TrackedEmptyState
            title={title}
            subtitle={subtitle}
            icon={<SearchIcon />}
            trackedLabel="patient-library-empty"
          />
        </Card>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      {availableCollections.map((collection) => (
        <ArticleCollectionCard
          key={collection.id}
          collection={collection}
          patientId={patientId}
          articles={articlesByCollection[collection.id] ?? []}
          isLoading={isLoading}
          isSubscribed={subscriptions.some(
            (s) => s.article_collection_id === collection.id && s.is_active
          )}
          reload={reload}
          reloadSubscriptions={reloadSubscriptions}
          isLoadingSubscriptions={isLoadingSubscriptions}
        />
      ))}
    </div>
  );
}

type ArticleCollectionCardProps = {
  patientId: string;
  articles: Article[];
  collection: ArticleCollection;
  isLoading: boolean;
  reload: () => Promise<any>;
  isSubscribed: boolean;
  reloadSubscriptions: () => Promise<any>;
  isLoadingSubscriptions: boolean;
};

function ArticleCollectionCard({
  collection,
  patientId,
  articles,
  isLoading,
  reload,
  isSubscribed,
  reloadSubscriptions,
  isLoadingSubscriptions,
}: ArticleCollectionCardProps) {
  const { isMobile } = useDevice();
  const intl = useIntl();

  const {
    sortedData: sortedArticles,
    sortBy,
    setSortBy,
  } = useTableSort<Article>(articles, '-notificationDate', {
    notificationDate: 'string',
    sentDate: 'string',
    viewDate: 'string',
  });

  const sortingOptions = [
    {
      label: intl.formatMessage({
        id: 'content-library.articles-table.title',
        defaultMessage: 'Title',
      }),
      value: 'title',
    },
    isSubscribed && {
      label: intl.formatMessage({
        id: 'content-library.articles-table.scheduled',
        defaultMessage: 'Scheduled',
      }),
      value: 'notificationDate',
    },
    {
      label: intl.formatMessage({
        id: 'content-library.articles-table.sent',
        defaultMessage: 'Sent',
      }),
      value: 'sentDate',
    },
    {
      label: intl.formatMessage({
        id: 'content-library.articles-table.viewed',
        defaultMessage: 'Viewed',
      }),
      value: 'viewDate',
    },
  ].filter(Boolean) as { label: string; value: string }[];

  return (
    <Card
      title={
        <div className={styles.actions}>
          <div>{collection.title}</div>
          <div className={styles.sortByMenu}>
            <TrackedSortByMenu
              trackLabel="patient-library-sort-by"
              selectedOption={sortBy}
              options={sortingOptions}
              tooltipText={intl.formatMessage({
                id: 'content-library.articles-table.sort-by',
                defaultMessage: 'Sort articles',
              })}
              onChange={setSortBy}
              size="xs"
              variant="secondary"
            />
          </div>
        </div>
      }
      key={collection.id}
    >
      {collection.allowSubscription && (
        <ContentSubscription
          patientId={patientId}
          collectionId={collection.id}
          isSubscribed={isSubscribed}
          reload={reloadSubscriptions}
          isLoading={isLoadingSubscriptions}
        />
      )}

      <div className={styles.content}>
        {isMobile ? (
          <ContentArticlesCards
            patientId={patientId}
            articles={sortedArticles}
            isLoading={isLoading}
            reload={reload}
            isSubscribed={isSubscribed}
          />
        ) : (
          <ContentArticlesTable
            patientId={patientId}
            articles={sortedArticles}
            isLoading={isLoading}
            reload={reload}
            sortBy={sortBy}
            setSortBy={setSortBy}
            isSubscribed={isSubscribed}
          />
        )}
      </div>
    </Card>
  );
}
