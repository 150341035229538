import { STAFF_ROLE_ID } from '@allurion/domain';
import { Card } from '@allurion/ui';
import { isError } from '@allurion/utils';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { useTrackEvent } from 'src/analytics/analytics';
import { createProviderUser } from 'src/api/UserApi';
import { toastSuccess, toastError } from 'src/components/ui/toasts';
import { ParsedClinicData } from 'src/domain/Clinic';
import { ProviderForm } from 'src/forms/User/ProviderForm';
import { getUserLanguage } from 'src/helpers/locale';
import { useLocale } from 'src/hooks/useLocale';
import { useProviderRoleLabels } from 'src/hooks/useProviderRoleLabels';
import { Logger } from 'src/services/Logger';

import translations from '../ManageUsers.translations';

import { ExpirationWarning } from './ExpirationWarning';

type FormValues = {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  clinics: string[];
};

type Props = {
  onCreate: (state: any) => void;
  goBack: () => void;
  clinics: ParsedClinicData[];
};

export function InviteProvider({ onCreate, goBack, clinics }: Props) {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const { trackFormSuccess, trackFormError } = useTrackEvent();

  const { locale } = useLocale();
  const lang = getUserLanguage(locale);

  const providerRoles: { [key: string]: string } = useProviderRoleLabels();

  const createUser = async (values: FormValues) => {
    setIsLoading(true);
    const clinicLabel = values.clinics
      ?.map((id) => clinics.find((clinic) => clinic.id === id)?.label)
      .join(', ');

    try {
      const newProvider = {
        email: values.email,
        lang,
        firstname: values.firstName,
        lastname: values.lastName,
        role: values.role,
        clinics: values.clinics,
      };

      const roleLabel = providerRoles[values.role];

      await createProviderUser(newProvider);
      setIsLoading(false);
      toastSuccess(intl.formatMessage(translations.userFormWrapperSuccessMessage));
      trackFormSuccess('invite-provider');
      onCreate({
        formEmail: newProvider.email,
        formFirstName: newProvider.firstname,
        formLastName: newProvider.lastname,
        clinicLabel,
        roleLabel: roleLabel,
      });
    } catch (error) {
      const errorMessage = isError(error) ? error.message : error;

      Logger.captureException(error);
      setIsLoading(false);
      toastError(intl.formatMessage(translations.userFormWrapperDuplicateUserMessage));
      trackFormError('invite-provider', { error: errorMessage });
    }
  };

  const onSubmit = (values: FormValues) => {
    createUser(values);
  };

  const initialValues: FormValues = {
    firstName: '',
    lastName: '',
    email: '',
    role: STAFF_ROLE_ID,
    clinics: [],
  };

  const hoursUntilExpires = 48;

  return (
    <ProviderForm
      title={intl.formatMessage(translations.userFormWrapperInviteNewUser)}
      description={
        <Card
          title={intl.formatMessage({
            id: 'invite-provider.alert.title',
            defaultMessage: 'Be aware of:',
          })}
        >
          <Label>{intl.formatMessage(translations.userFormWrapperCreatePassword)}</Label>
          <ExpirationWarning hoursUntilExpires={hoursUntilExpires} />
        </Card>
      }
      goBack={goBack}
      isEditForm={false}
      isLoading={isLoading}
      initialValues={initialValues}
      onSubmit={onSubmit}
      allClinics={clinics}
    />
  );
}

const Label = styled.label`
  display: inline-block;
  font-size: 18px;
  margin-bottom: 15px;
  color: ${({ theme }) => theme.colors.Primary};
`;
