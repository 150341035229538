import { Card, FormGroup, InputGroup, InputSelector, TextInput } from '@allurion/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { z } from 'zod';

import { TrackedButton } from 'src/analytics/TrackedUI';
import { useCurrentProvider } from 'src/hooks/useCurrentProvider';
import { useUserClinics } from 'src/hooks/useUserClinics';
import globalMessages from 'src/messages/global.messages';

import messages from './terms-and-conditions-messages';

import styles from './TermsAndConditionsForm.module.scss';

type Props = {
  initialValues: Partial<FormValues>;
  onSubmit: (values: FormValues) => void;
};

type FormValues = {
  accepted: boolean;
  firstName: string;
  lastName: string;
  date: string;
};

export function TermsAndConditionsForm({ initialValues, onSubmit }: Props) {
  const intl = useIntl();
  const { provider } = useCurrentProvider();
  const { clinics: locations } = useUserClinics();

  const TermsAndConditionsFormSchema = z
    .object({
      accepted: z.boolean().refine((val) => val, intl.formatMessage(globalMessages.required)),
      firstName: z.string().nonempty(intl.formatMessage(globalMessages.required)),
      lastName: z.string().nonempty(intl.formatMessage(globalMessages.required)),
      date: z.string().nonempty(intl.formatMessage(globalMessages.required)),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: zodResolver(TermsAndConditionsFormSchema),
    defaultValues: initialValues,
  });

  const personalizedAgreement = useMemo(() => {
    if (!provider) {
      return intl.formatMessage(messages.terms);
    }

    const fullName = `${provider?.FirstName || ''} ${provider?.LastName || ''}`;

    const locationsNames = locations?.map((c) => c?.post_title) || 'Allurion';
    const clinicName = joinClinicNames(
      locationsNames,
      intl.formatMessage({ id: 'global.and', defaultMessage: 'and' })
    );
    // const clinicName: string = clinic?.post_title ?? 'Allurion';

    // NOTE: We can update the translations to use format => {firstName lastName}, {clinicName}
    // instead of <Clinic Name> and <First Name Last Name>
    // to drop the in-house replace implementation, removes: nameReplacement, clinicReplacement and finalString
    const template = intl.formatMessage(messages.terms);
    const nameReplacement = template.replace('<First Name Last Name>', fullName);
    const clinicReplacement = nameReplacement.replace('<Clinic Name>', clinicName);
    const finalString = clinicReplacement.replace('<Clinic Name>', clinicName);

    return finalString;
  }, [intl, provider, locations]);

  return (
    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <FormGroup error={errors.accepted?.message}>
          <InputSelector
            type="checkbox"
            label={personalizedAgreement}
            {...register('accepted', {
              required: true,
            })}
          />
        </FormGroup>
        <div className={styles.text}>{intl.formatMessage(messages.instructions)}</div>
        <div className={styles.row}>
          <div className={styles.fieldWrapper}>
            <FormGroup
              label={intl.formatMessage(messages.firstName)}
              error={errors.firstName?.message}
            >
              <TextInput
                type="text"
                placeholder={intl.formatMessage(messages.firstName)}
                {...register('firstName', {
                  required: true,
                })}
              />
            </FormGroup>
          </div>
          <div className={styles.fieldWrapper}>
            <FormGroup
              label={intl.formatMessage(messages.lastName)}
              error={errors.lastName?.message}
            >
              <TextInput
                type="text"
                placeholder={intl.formatMessage(messages.lastName)}
                {...register('lastName', {
                  required: true,
                })}
              />
            </FormGroup>
          </div>
        </div>
        <div className={styles.fieldWrapper}>
          <FormGroup label={intl.formatMessage(messages.todaysDate)} error={errors.date?.message}>
            <InputGroup
              textInput={{
                type: 'date',
                ...register('date', {
                  required: true,
                }),
              }}
            />
          </FormGroup>
        </div>
        <div className={styles.controls}>
          <TrackedButton
            type="submit"
            label={intl.formatMessage(messages.submit)}
            trackLabel="submit-terms-and-conditions"
          />
        </div>
      </Card>
    </form>
  );
}

function joinClinicNames(arr: string[], delim = 'and') {
  if (arr.length > 2) {
    return `${arr.slice(0, -1).join(', ')} ${delim} ${arr.slice(-1)}`;
  }
  if (arr.length === 2) {
    return arr.join(` ${delim} `);
  }

  return arr[0];
}
