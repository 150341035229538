import {
  FormGroup,
  HelpIcon,
  IconButton,
  QrCodeIcon,
  SelectInput,
  TextInput,
  useDialog,
} from '@allurion/ui';
import { isError, toLocalizedDate } from '@allurion/utils';
import { zodResolver } from '@hookform/resolvers/zod';
import { Html5Qrcode } from 'html5-qrcode';
import { ComponentProps, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import * as z from 'zod';

import { TrackedButton } from 'src/analytics/TrackedUI';
import { Loader } from 'src/components/ui/Loader';
import { toastError } from 'src/components/ui/toasts';
import { useLocale } from 'src/hooks/useLocale';
import { usePatientTreatments } from 'src/hooks/usePatientTreatments';
import { useTreatmentLabels } from 'src/hooks/useTreatmentLabels';
import globalMessages from 'src/messages/global.messages';

import { BalloonPlacementManualHelp } from './BalloonPlacementManualInputHelp';
import { QRCodeReader } from './QRCodeReader';
import { ParsedBalloonQRCode, ScanBalloonQRCode } from './ScanBalloonQRCode';

import styles from './PatientBalloonPlacementForm.module.scss';

type Props = {
  patientId: string;
  clinicId: string;
  onSave: (placement: BalloonPlacingFormValues) => void;
  onCancel: () => void;
};

const BalloonPlacingFormSchema = z.object({
  patientTreatmentId: z.string().nonempty({ message: 'Required' }),
  serialNumber: z.string().nonempty({ message: 'Required' }),
  manufacturingLot: z.string().nonempty({ message: 'Required' }),
  placementDate: z.string().nonempty({ message: 'Required' }),
  expirationDate: z.string().optional(),
  manufacturingDate: z.string().optional(),
});

type BalloonPlacingFormValues = z.infer<typeof BalloonPlacingFormSchema>;

export function PatientBalloonPlacementForm({ clinicId, patientId, onSave, onCancel }: Props) {
  const { getTreatmentCategoryLabel, getTreatmentLabel } = useTreatmentLabels(clinicId);
  const { treatments } = usePatientTreatments(patientId);
  const intl = useIntl();
  const [isSaving, setIsSaving] = useState(false);
  const qrScannerRef = useRef<Html5Qrcode | null>(null);
  const [scannerUsed, setScannerUsed] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<BalloonPlacingFormValues>({
    resolver: zodResolver(BalloonPlacingFormSchema),
  });

  const { locale } = useLocale();

  const treatmentsOptions =
    treatments?.map((treatment) => {
      return {
        label: `${getTreatmentCategoryLabel(treatment.treatmentCategoryId, 'short')}
        /
        ${getTreatmentLabel(treatment.treatmentId, 'short')} : ${toLocalizedDate(treatment.startDate, locale)}`,
        value: treatment.TreatmentUserID,
      };
    }) ?? [];

  const { openDialog, Dialog } = useDialog<ComponentProps<typeof ScanBalloonQRCode>>({
    content: ({ close, ...contentProps }) => {
      return (
        <div onClick={(e) => e.stopPropagation()}>
          <ScanBalloonQRCode
            {...contentProps}
            onValid={async (result) => {
              await onValidQrCode(result);
              close?.();
            }}
          />
        </div>
      );
    },
    onClose: () => {
      QRCodeReader.stop();
    },
  });

  const { openDialog: showManualInputHelp, Dialog: ManualInputHelpDialog } = useDialog<
    ComponentProps<typeof BalloonPlacementManualHelp>
  >({
    content: ({ close, ...contentProps }) => {
      return (
        <div onClick={(e) => e.stopPropagation()} style={{ maxWidth: '500px' }}>
          <BalloonPlacementManualHelp {...contentProps} />
        </div>
      );
    },
  });

  return (
    <>
      <Dialog />
      <ManualInputHelpDialog />

      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <Loader isLoading={isSaving} />

        <div className={styles.content}>
          <FormGroup
            label={intl.formatMessage({
              id: 'patient-balloon-tracking.form.treatment',
              defaultMessage: 'Treatment',
            })}
            error={translateValidationError(errors.patientTreatmentId?.message)}
          >
            <SelectInput {...register('patientTreatmentId')} options={treatmentsOptions} />
          </FormGroup>

          <FormGroup
            label={intl.formatMessage({
              id: 'patient-balloon-tracking.form.placement-date',
              defaultMessage: 'Placement date',
            })}
            error={translateValidationError(errors.placementDate?.message)}
            help={intl.formatMessage({
              id: 'patient-balloon-tracking.form.placement-date-placeholder',
              defaultMessage: 'Please enter the date of balloon placement',
            })}
          >
            <TextInput {...register('placementDate')} type="date" />
          </FormGroup>

          <div className={styles.scanOrType}>
            <TrackedButton
              leftIcon={<QrCodeIcon />}
              label={intl.formatMessage({
                id: 'patient-balloon-tracking.form.scan-qr-code',
                defaultMessage: 'Scan QR code',
              })}
              type="button"
              onClick={handleScan}
              variant="secondary"
              trackLabel="scan-qr-code-ballon-placement-form"
            />
            <p>or enter manually:</p>
          </div>

          <FormGroup
            label={
              <>
                {intl.formatMessage({
                  id: 'patient-balloon-tracking.form.manufactoring',
                  defaultMessage: 'Manufacturing lot number',
                })}
                <IconButton
                  size="xxs"
                  variant="icon"
                  icon={<HelpIcon />}
                  onClick={showManualInputHelpDialog}
                />
              </>
            }
            error={translateValidationError(errors.manufacturingLot?.message)}
          >
            <TextInput
              {...register('manufacturingLot')}
              placeholder={intl.formatMessage({
                id: 'patient-balloon-tracking.form.manufactoring-placeholder',
                defaultMessage: 'Enter manufacturing lot or scan QR code',
              })}
              disabled={scannerUsed}
            />
          </FormGroup>

          <FormGroup
            label={
              <>
                {intl.formatMessage({
                  id: 'patient-balloon-tracking.form.serial',
                  defaultMessage: 'Serial number',
                })}
                <IconButton
                  size="xxs"
                  variant="icon"
                  icon={<HelpIcon />}
                  onClick={showManualInputHelpDialog}
                />
              </>
            }
            error={translateValidationError(errors.serialNumber?.message)}
          >
            <TextInput
              {...register('serialNumber')}
              placeholder={intl.formatMessage({
                id: 'patient-balloon-tracking.form.serial-placeholder',
                defaultMessage: 'Enter serial number or scan QR code',
              })}
              disabled={scannerUsed}
            />
          </FormGroup>
        </div>

        <div className={styles.footer}>
          <TrackedButton
            label={intl.formatMessage(globalMessages.cancel)}
            type="button"
            onClick={handleCancel}
            variant="secondary"
            trackLabel="cancel-ballon-placement-form"
          />
          <TrackedButton
            label={intl.formatMessage({
              id: 'patient-balloon-tracking.form.track',
              defaultMessage: 'Save',
            })}
            type="submit"
            trackLabel="submit-ballon-placement-form"
          />
        </div>
      </form>
    </>
  );

  function showManualInputHelpDialog() {
    showManualInputHelp({
      title: intl.formatMessage({
        id: 'patient-balloon-tracking.manual-input-help',
        defaultMessage: 'Ballon placement manual input help',
      }),
      contentProps: {},
    });
  }

  async function onValidQrCode(result: ParsedBalloonQRCode): Promise<void> {
    setValue('serialNumber', result.serialNumber);
    setValue('manufacturingLot', result.manufacturingLotNumber);
    setValue('manufacturingDate', result.manufacturingDate);
    setValue('expirationDate', result.expirationDate);
    setScannerUsed(true);
  }

  async function onSubmit(data: BalloonPlacingFormValues) {
    setIsSaving(true);
    try {
      await onSave(data);
    } catch (error) {
      let message = (isError(error) ? error.message : error) as string;

      if (message.startsWith('Duplicated record with fields')) {
        message = intl.formatMessage({
          id: 'patient-balloon-tracking.duplicated-record-error',
          defaultMessage: 'This balloon is already registered',
        });
      }

      toastError(message);
    } finally {
      setIsSaving(false);
    }
  }

  function handleCancel() {
    if (qrScannerRef.current) {
      qrScannerRef.current.stop();
      qrScannerRef.current.clear();
    }
    onCancel();
  }

  async function handleScan() {
    openDialog({
      title: intl.formatMessage({
        id: 'patient-balloon-tracking.scan-balloon-qr-code',
        defaultMessage: 'Scan QR code',
      }),
      //@ts-expect-error
      contentProps: {},
    });
  }

  function translateValidationError(message: string | undefined) {
    if (!message) {
      return undefined;
    }

    switch (message) {
      case 'Required':
        return intl.formatMessage(globalMessages.required);

      default:
        return message;
    }
  }
}
