import { useIntl } from 'react-intl';

import currentEnv from 'src/utils/env';

import messages from './footer.messages';

import styles from './Footer.module.scss';

const appVersion = `Build ${currentEnv.APP_BUILD_NUMBER}`;

export function Footer() {
  const intl = useIntl();

  const year = new Date().getFullYear();

  const copyright = `© ${year} Allurion Technologies`;
  const privacyLink = intl.formatMessage(messages.privacyPolicyLink);

  const email = 'help@allurion.com';
  const subject = 'Insights Assistance';
  const mailToLink = `mailto:${email}?subject=${subject}`;

  return (
    <div className={styles.container} data-sentry-unmask data-cy="footer">
      <span>{copyright}</span>
      <span className={styles.separator} />
      <a href={privacyLink} target="_blank" rel="noreferrer">
        {intl.formatMessage(messages.privacyPolicy)}
      </a>
      <span className={styles.separator} />
      <a href={mailToLink} target="_blank" rel="noreferrer">
        {email}
      </a>
      <div className={styles.appVersion}>{appVersion}</div>
    </div>
  );
}
