import { useModal } from './Modal';
import { CloseIcon } from '../../icons';

import styles from './Dialog.module.scss';
import { PropsWithChildren } from 'react';
import { IconButton } from '../IconButton/IconButton';
import { Button } from '../Button/Button';

type DialogProps = {
  title: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  confirmText?: string;
  cancelText?: string;
};

type DialogPropsWithClose = DialogProps & {
  close?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

type UseDialogProps<T> = {
  content: (
    props: T & { close?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void }
  ) => JSX.Element;
  onClose?: () => void;
};

type UseModalProps<T> = DialogProps & {
  contentProps: T;
};

export function useDialog<T>({ content, onClose }: UseDialogProps<T>) {
  const { open, Modal } = useModal<UseModalProps<T>>({
    content: ({ contentProps, close, ...props }) => (
      <Dialog {...props} close={close}>
        {content({ ...contentProps, close })}
      </Dialog>
    ),
    onClose,
  });

  return {
    openDialog: open,
    Dialog: Modal,
  };
}

function Dialog({
  title,
  onConfirm,
  onCancel,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  children,
  close,
}: PropsWithChildren<DialogPropsWithClose>) {
  const hasButtons = onConfirm || onCancel;

  return (
    <div className={styles.container} onClick={(e) => e.stopPropagation()}>
      <div className={styles.header}>
        <IconButton icon={<CloseIcon />} onClick={handleCancel} variant="icon" />
        <h2>{title}</h2>
      </div>

      <div className={styles.content}>{children}</div>

      {hasButtons && (
        <div className={styles.buttons}>
          {onCancel && (
            <Button type="button" variant="secondary" onClick={handleConfirm} label={cancelText} />
          )}
          {onConfirm && (
            <Button type="button" variant="primary" onClick={handleCancel} label={confirmText} />
          )}
        </div>
      )}
    </div>
  );

  function handleConfirm(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    onConfirm?.();
    close?.(e);
  }

  function handleCancel(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    onCancel?.();
    close?.(e);
  }
}
