import { Table, TrashCanIcon, useConfirmationDialog } from '@allurion/ui';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { NavigateOptions } from 'react-router-dom';

import { disableUser } from 'src/api/UserApi';
import { InlineLoader } from 'src/components/ui/InlineLoader';
import { Loader } from 'src/components/ui/Loader';
import { toastError, toastSuccess } from 'src/components/ui/toasts';
import { ParsedClinicData } from 'src/domain/Clinic';
import { ClinicProvider, useManageClinicsProviders } from 'src/hooks/useManageClinicsProviders';
import globalMessages from 'src/messages/global.messages';
import { Logger } from 'src/services/Logger';
import { useTableSort } from 'src/ui/useTableSort';

import { Pagination } from '../../components/Pagination';
import { Spacer } from '../shared-page-elements';

import translations from './ManageUsers.translations';

type Props = {
  search: string;
  clinics?: ParsedClinicData[];
  onRowClick: (providerId: string, options?: NavigateOptions) => void;
  onResendInviteClick: (options: NavigateOptions) => void;
};

export function ManageUsersTable({ search, clinics, onRowClick, onResendInviteClick }: Props) {
  const intl = useIntl();
  const { isLoading, providers, reload } = useManageClinicsProviders(clinics);
  const [page, setPage] = useState(1);
  const defaultPageSize = 10;
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const { askConfirmationPromise, ConfirmationDialog } = useConfirmationDialog();
  const [isUpdating, setIsUpdating] = useState(false);

  const filteredProviders = useMemo(
    () =>
      providers.filter((provider) => {
        const { FirstName, LastName, EmailAddress, ClinicLabel } = provider;

        return (
          FirstName?.toLowerCase().includes(search.toLowerCase()) ||
          LastName?.toLowerCase().includes(search.toLowerCase()) ||
          EmailAddress?.toLowerCase().includes(search.toLowerCase()) ||
          ClinicLabel.toLowerCase().includes(search.toLowerCase())
        );
      }),
    [providers, search]
  );
  const {
    sortedData: sortedProviders,
    sortBy,
    setSortBy,
  } = useTableSort<ClinicProvider>(filteredProviders, '+FirstName', {});

  const paginatedProviders = sortedProviders.slice((page - 1) * pageSize, page * pageSize);
  const lastPage = Math.ceil(sortedProviders.length / pageSize);

  if (isLoading && providers.length === 0) {
    return <InlineLoader />;
  }

  const handleRowClick = (e: React.MouseEvent<HTMLAnchorElement>, provider: ClinicProvider) => {
    e.stopPropagation();
    e.preventDefault();
    onRowClick(provider.ProviderID, { state: { user: provider } });
  };

  return (
    <>
      <ConfirmationDialog />
      <Table
        columns={[
          {
            title: intl.formatMessage(translations.manageUsersTableFirstName),
            field: 'FirstName',
            formatter(_, provider) {
              return (
                <a href="" onClick={(e) => handleRowClick(e, provider)}>
                  {provider.FirstName}
                </a>
              );
            },
          },
          {
            title: intl.formatMessage(translations.manageUsersTableLastName),
            field: 'LastName',
            formatter(_, provider) {
              return (
                <a href="" onClick={(e) => handleRowClick(e, provider)}>
                  {provider.LastName}
                </a>
              );
            },
          },
          {
            title: intl.formatMessage(translations.manageUsersTableEmail),
            field: 'EmailAddress',
          },
          {
            title: intl.formatMessage(translations.manageUsersTableClinic),
            field: 'ClinicLabel',
          },
          {
            title: intl.formatMessage(translations.manageUsersTableRole),
            field: 'RoleID',
            formatter(_, row) {
              return formatRole(row);
            },
          },
          {
            title: intl.formatMessage(translations.manageUsersTableRowActions),
            field: 'ProviderID',
            formatter(_, provider) {
              return (
                <a
                  href=""
                  onClick={(e) => handleProviderDeletion(e, provider)}
                  title={intl.formatMessage(translations.deleteProviderBtnTitle)}
                >
                  <TrashCanIcon />
                </a>
              );
            },
          },
        ]}
        data={paginatedProviders}
        sortBy={sortBy}
        onSortChange={setSortBy}
      />
      <Spacer />
      <Pagination
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        lastPage={lastPage}
        defaultPageSize={defaultPageSize}
        pageSizeLabel={intl.formatMessage({
          id: 'manage-users-table.page-size-label',
          defaultMessage: 'Users per page',
        })}
      />
      <Loader isLoading={isUpdating} />
    </>
  );

  function formatRole(provider: ClinicProvider) {
    const { IsVerified } = provider;
    const label = {
      '1': 'Super User',
      '2': intl.formatMessage(translations.manageUsersAdmin),
      '3': intl.formatMessage(translations.manageUsersStaff),
      '4': intl.formatMessage(translations.manageUsersCoach),
    };

    return (
      <>
        {label[provider.RoleID as keyof typeof label]}
        {!IsVerified && (
          <>
            &nbsp;-&nbsp;
            {intl.formatMessage(translations.manageUsersTableInvited)}
            &nbsp;-&nbsp;
            <a href="" onClick={(e) => handleResendInvitationLink(e, provider)}>
              {intl.formatMessage(translations.manageUsersTableResendInvite)}
            </a>
          </>
        )}
      </>
    );
  }

  function handleResendInvitationLink(
    e: React.MouseEvent<HTMLAnchorElement>,
    provider: ClinicProvider
  ) {
    e.preventDefault();
    e.stopPropagation();

    onResendInviteClick({ state: { user: provider } });
  }

  function handleProviderDeletion(
    e: React.MouseEvent<HTMLAnchorElement>,
    provider: ClinicProvider
  ) {
    e.preventDefault();
    e.stopPropagation();

    // onProviderDeletion(provider);
    //FIXME
    disableProvider(provider.ProviderID);
  }

  async function disableProvider(providerId: string) {
    if (!providerId) {
      return;
    }

    const shouldDeleteUser = await askConfirmationPromise({
      title: intl.formatMessage({
        id: 'edit-provider-page.delete-btn',
        defaultMessage: 'Are you sure you want to delete this user? All the data will be removed',
      }),
      confirmText: intl.formatMessage(globalMessages.delete),
      cancelText: intl.formatMessage(globalMessages.cancel),
      variant: 'danger',
    });

    if (!shouldDeleteUser) {
      return;
    }

    setIsUpdating(true);

    try {
      await disableUser(providerId);
      setIsUpdating(false);
      toastSuccess(intl.formatMessage(translations.userFormWrapperSuccessDeleteUser));
      reload();
    } catch (error) {
      Logger.captureException(error);
      setIsUpdating(false);
      toastError(intl.formatMessage(translations.userFormWrapperDeleteUserFailure));
    }
  }
}
