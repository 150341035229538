import { useIntl } from 'react-intl';

import helpImgSrc from 'src/images/ballon-placement-label-help-highlighted.png';

export function BalloonPlacementManualHelp() {
  const intl = useIntl();

  return (
    <div style={{ padding: '1rem', color: '#335e65' }}>
      <p>
        {intl.formatMessage({
          id: 'patient-ballon-placement-help.first-paragraph',
          defaultMessage:
            'If you are unable to scan the QR code, you can manually input the information from the balloon.',
        })}
      </p>

      <p style={{ marginTop: '1rem' }}>
        {intl.formatMessage({
          id: 'patient-ballon-placement-help.second-paragraph',
          defaultMessage:
            'The information you need to input is the serial number (highlighted in blue) and manufacturing lot number (highlighted in red).',
        })}
      </p>

      <img
        src={helpImgSrc}
        alt="Example of ballon placement label"
        style={{ maxWidth: '100%', marginTop: '1rem' }}
      />
    </div>
  );
}
