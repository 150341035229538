import { scrollIntoView } from '@allurion/ui';
import { useLayoutEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { useCoachIrisHistory } from 'src/hooks/useCoachIrisHistory';
import { useIsVisible } from 'src/hooks/useIsVisible';

import { CoachIrisMessages } from './CoachIrisMessages';
import { CoachIrisSummary } from './CoachIrisSummary';

import styles from './CoachIrisHistory.module.scss';

type Props = {
  patientId: string;
};

export function CoachIrisHistory({ patientId }: Props) {
  const [firstLoad, setFirstLoad] = useState(true);
  const { messages, loadMore, isLoadingMore, isReachingEnd } = useCoachIrisHistory(patientId);

  const [showSummary, setShowSummary] = useState(true);
  const ref = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const isContainerVisible = useIsVisible(containerRef);
  const intl = useIntl();

  useLayoutEffect(() => {
    if (firstLoad && isContainerVisible && messages.length > 0 && !showSummary) {
      setFirstLoad(false);
      setTimeout(() => {
        scrollIntoView(ref.current);
      }, 500);
    }
  }, [firstLoad, isContainerVisible, messages, showSummary]);

  return (
    <div className={styles.container} ref={containerRef}>
      <div className={styles.innerContainer}>
        <button
          type="button"
          onClick={() => setShowSummary(!showSummary)}
          className={styles.toggleButton}
        >
          {showSummary
            ? intl.formatMessage({
                id: 'coach-iris.show-details-button',
                defaultMessage: 'Show Details',
              })
            : intl.formatMessage({
                id: 'coach-iris.show-summary-button',
                defaultMessage: 'Show Summary',
              })}
        </button>

        {showSummary && messages.length > 0 ? (
          <CoachIrisSummary patientId={patientId} />
        ) : (
          <CoachIrisMessages
            loadMore={loadMore}
            messages={messages}
            isLoadingMore={isLoadingMore}
            isReachingEnd={isReachingEnd}
          />
        )}
        <div ref={ref}></div>
      </div>
    </div>
  );
}
