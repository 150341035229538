import { PatientCoachIrisHistory } from '@allurion/domain';

import styles from './CoachIrisHistory.module.scss';

type Props = {
  loadMore: () => void;
  messages: PatientCoachIrisHistory[];
  isLoadingMore: boolean;
  isReachingEnd: boolean;
};

export function CoachIrisMessages({ loadMore, messages, isLoadingMore, isReachingEnd }: Props) {
  return (
    <>
      <button
        type="button"
        onClick={loadMore}
        disabled={isLoadingMore || isReachingEnd}
        className={styles.loadMoreButton}
      >
        {isLoadingMore ? 'loading...' : isReachingEnd ? 'end of history' : 'Load more'}
      </button>

      {messages.map((message) => (
        <div
          key={message.timestamp}
          className={[
            styles.message,
            isUserMessage(message) ? styles.patientMessage : styles.coachIrisMessage,
          ]
            .filter(Boolean)
            .join(' ')}
        >
          <div className={styles.header}>
            {isUserMessage(message) ? (
              ''
            ) : (
              <>
                <span className={styles.icon}>I</span>
                <span className={styles.user}>Coach Iris • </span>
              </>
            )}
            <div className={styles.timestamp}>{formatTimestamp(message.timestamp)}</div>
          </div>

          <div className={styles.content}>{message.content}</div>
        </div>
      ))}
    </>
  );
}

function formatTimestamp(timestamp: number | undefined) {
  if (!timestamp) {
    return '';
  }

  return new Date(timestamp).toLocaleString();
}

function isUserMessage(message: PatientCoachIrisHistory) {
  return message.source === 'user';
}
