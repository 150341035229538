import { Card, FormGroup, InputSelector, TextInput } from '@allurion/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import * as z from 'zod';

import { TrackedButton, TrackedPageHeader } from 'src/analytics/TrackedUI';
import { InlineLoader } from 'src/components/ui/InlineLoader';
import { Loader } from 'src/components/ui/Loader';
import { ParsedClinicData } from 'src/domain/Clinic';
import { useDevice } from 'src/hooks/useDevice';
import { useProviderRoleLabels } from 'src/hooks/useProviderRoleLabels';
import globalMessages from 'src/messages/global.messages';
import inviteUserMessages from 'src/messages/invite-user.messages';

import messages from './user-form-messages';

import styles from './ProviderForm.module.scss';

type Props = {
  initialValues: any;
  onSubmit: (values: any) => void;
  isLoading: boolean;
  isEditForm?: boolean;
  allClinics: ParsedClinicData[];
  providerClinics?: string[];
  title: string;
  goBack: () => void;
  description?: React.ReactNode;
};

export function ProviderForm({
  initialValues,
  onSubmit,
  allClinics,
  isEditForm = false,
  isLoading = false,
  title,
  goBack,
  description,
}: Props) {
  const intl = useIntl();
  const { isMobile } = useDevice();
  const ProviderFormSchema = z.object({
    firstName: z.string().nonempty(intl.formatMessage(globalMessages.required)),
    lastName: z.string().nonempty(intl.formatMessage(globalMessages.required)),
    //might need extra validation /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/
    email: z.string().email(intl.formatMessage(globalMessages.notValidEmail)),
    clinics: z
      .array(z.string().nonempty(intl.formatMessage(globalMessages.required)))
      .min(1, intl.formatMessage(globalMessages.required)),
    role: z.string().nonempty(intl.formatMessage(globalMessages.required)),
  });
  const providerRoles = useProviderRoleLabels();

  type FormValues = z.infer<typeof ProviderFormSchema>;
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isDirty },
    watch,
  } = useForm<FormValues>({
    resolver: zodResolver(ProviderFormSchema),
    defaultValues: initialValues,
  });
  const radioRoleOpts = Object.entries(providerRoles).map(([value, label]) => ({
    value,
    label,
    id: label.toLowerCase(),
  }));

  if (!isEditForm) {
    initialValues.role = '';
  }

  if (isLoading) {
    return <InlineLoader />;
  }

  const buttonLabel = isEditForm
    ? intl.formatMessage(messages.updateUser)
    : intl.formatMessage(inviteUserMessages.addButton);

  const clinicOptions: {
    value: string;
    label: string;
  }[] = allClinics
    .map((clinic) => ({
      value: clinic.id,
      label: clinic.clinicName,
    }))
    .filter((provider) => provider.label.trim().length > 0)
    .sort((a, b) => a.label.localeCompare(b.label));

  const selectedClinics = watch('clinics');
  const providerGridMaxHeight = `${clinicOptions.length > 20 ? clinicOptions.length * 10 : 200}px`;

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
      <TrackedPageHeader
        title={title}
        onNavButtonClick={goBack}
        button={{
          label: buttonLabel,
          disabled: !isDirty,
          size: 'sm',
          trackLabel: 'submit-provider-form',
          type: 'submit',
        }}
      />
      <Loader isLoading={isLoading} />

      <div className={styles.container}>
        {description}
        <Card title={intl.formatMessage(messages.personalInformationTitle)}>
          <FormGroup
            label={intl.formatMessage(messages.firstName)}
            error={errors.firstName?.message}
          >
            <TextInput
              placeholder={intl.formatMessage(messages.firstName)}
              {...register('firstName')}
            />
          </FormGroup>

          <FormGroup label={intl.formatMessage(messages.lastName)} error={errors.lastName?.message}>
            <TextInput
              placeholder={intl.formatMessage(messages.lastName)}
              {...register('lastName')}
            />
          </FormGroup>

          <FormGroup label={intl.formatMessage(messages.email)} error={errors.email?.message}>
            <TextInput
              type="email"
              placeholder={intl.formatMessage(messages.email)}
              disabled={isEditForm}
              {...register('email')}
            />
          </FormGroup>

          <FormGroup label={intl.formatMessage(messages.role)} error={errors.role?.message}>
            <div className={styles.roleSelector}>
              {radioRoleOpts.map(({ label, value }) => (
                <InputSelector
                  {...register('role')}
                  type="radio"
                  label={label}
                  key={value}
                  value={value}
                />
              ))}
            </div>
          </FormGroup>
        </Card>

        <Card title={intl.formatMessage(messages.clinicAccessTitle)}>
          <div className={styles.providerHeader}>
            <p className={styles.providerHelpText}>
              ({intl.formatMessage(messages.clinicAccessDescription)})
            </p>
            <div className={styles.providerActions}>
              <TrackedButton
                label={intl.formatMessage(globalMessages.selectAll)}
                size="xs"
                variant="secondary"
                trackLabel="invite-patient-select-all-providers"
                onClick={selectAllProviders}
              />
              <TrackedButton
                label={intl.formatMessage(globalMessages.unselectAll)}
                size="xs"
                variant="secondary"
                trackLabel="invite-patient-unselect-all-providers"
                onClick={unselectAllProviders}
              />
            </div>
          </div>
          <FormGroup
            label={intl.formatMessage(messages.selectClinic)}
            error={errors.clinics?.message}
          >
            <div
              className={styles.providerGrid}
              style={{ maxHeight: isMobile ? 'auto' : providerGridMaxHeight }}
            >
              {clinicOptions.map((option) => (
                <InputSelector
                  key={option.value}
                  type="checkbox"
                  {...register('clinics')}
                  label={option.label}
                  value={option.value}
                  className={
                    selectedClinics?.includes(option.value) ? styles.selected : styles.unselected
                  }
                />
              ))}
            </div>
          </FormGroup>
        </Card>
      </div>
    </form>
  );

  function selectAllProviders() {
    setValue(
      'clinics',
      clinicOptions.map((option) => option.value)
    );
  }

  function unselectAllProviders() {
    setValue('clinics', []);
  }
}
