import { isError } from '@allurion/utils';
import { captureException } from '@sentry/react';
import { CameraDevice, Html5Qrcode } from 'html5-qrcode';

export class QRCodeReader {
  private static reader: Html5Qrcode | null = null;

  static async init(containerId: string) {
    // console.log('QRCodeReader.init');
    if (QRCodeReader.reader) {
      // console.log('QRCodeReader.reader.stop');
      await QRCodeReader.stop();
    }

    QRCodeReader.reader = new Html5Qrcode(containerId);
  }

  static async start<T>({
    parse,
    onValid,
  }: {
    parse: (text: string) => T | false;
    onValid: (result: T) => Promise<void>;
  }) {
    const cameraId = await QRCodeReader.getCameraId();
    const uniqueErrors = new Set<string>();
    const html5QrCode = QRCodeReader.reader;

    if (!html5QrCode) {
      return;
    }

    html5QrCode
      .start(
        cameraId,
        {
          fps: 10, // Optional, frame per seconds for qr code scanning
          qrbox: { width: 250, height: 250 }, // Optional, if you want bounded box UI
        },
        (decodedText: string) => {
          html5QrCode.pause(true);

          const parsed = parse(decodedText);

          if (!parsed) {
            html5QrCode.resume();

            return;
          }

          setTimeout(async () => {
            await onValid(parsed);
            QRCodeReader.stop();
          }, 1500);
        },
        (error) => {
          // parse error, ignore it.
          const message = isError(error) ? error.message : error;

          //Examples of errors:
          // QR code parse error, error = NotFoundException: No MultiFormat Readers were able to detect the code.
          // QR code parse error, error = No barcode or QR code detected.
          if (uniqueErrors.has(message)) {
            return;
          }

          uniqueErrors.add(message);
        }
      )
      .catch((error) => {
        captureException(error);
      });
  }

  static async stop() {
    const html5QrCode = QRCodeReader.reader;

    if (!html5QrCode) {
      return;
    }

    try {
      await html5QrCode.stop();
      await html5QrCode.clear();
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      // console.log('QRCodeReader.stop error', error);
    }

    QRCodeReader.reader = null;
  }

  private static async getCameraId() {
    let devices: CameraDevice[];

    try {
      // This method will trigger user permissions
      devices = await Html5Qrcode.getCameras();
    } catch (error) {
      captureException(error);

      throw new Error('Failed to get camera devices');
    }

    if (!devices || devices.length === 0) {
      throw new Error('No camera devices found');
    }

    const cameraId = devices[0].id;

    if (!cameraId) {
      throw new Error('No camera id found');
    }

    return cameraId;
  }
}
