import { FormGroup, TextInput } from '@allurion/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import * as z from 'zod';

import { TrackedButton } from 'src/analytics/TrackedUI';
import { PasswordPopup } from 'src/components/PasswordPopup/PasswordPopup';
import globalMessages from 'src/messages/global.messages';

import messages from './create-password.messages';

import styles from './CreatePasswordForm.module.scss';

type FormValues = {
  password: string;
  confirmPassword: string;
};

type Props = {
  initialValues: FormValues;
  onSubmit: (values: FormValues) => void;
};

export function CreatePasswordForm({ initialValues, onSubmit }: Props) {
  const intl = useIntl();

  const schema = z
    .object({
      /** password validation rules:
       * - Required: globalMessages.required
       * - At least eight characters: globalMessages.minChars
       * - At least one lowercase letter: globalMessages.oneLower
       * - At least one uppercase letter: globalMessages.oneCapital
       * - At least one number: globalMessages.oneNumber
       * - At least one special character: globalMessages.oneSpecialChar
       */
      password: z
        .string()
        .nonempty(intl.formatMessage(globalMessages.required))
        .min(8, intl.formatMessage(globalMessages.minChars))
        .regex(/[a-z]/, intl.formatMessage(globalMessages.oneLower))
        .regex(/[A-Z]/, intl.formatMessage(globalMessages.oneCapital))
        .regex(/[0-9]/, intl.formatMessage(globalMessages.oneNumber))
        .regex(/[!@#$%^&*()_+{}:;'"/\\]/, intl.formatMessage(globalMessages.oneSpecialChar)),
      /** confirmPassword validation rules:
       * - Required: globalMessages.required
       * - Must match password: globalMessages.passwordMustMatch
       */
      confirmPassword: z.string().nonempty(intl.formatMessage(globalMessages.required)),
    })
    .refine((data) => data.confirmPassword === data.password, {
      path: ['confirmPassword'],
      message: intl.formatMessage(globalMessages.passwordMustMatch),
    });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: zodResolver(schema),
    defaultValues: initialValues,
  });
  const isSubmitting = false;

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
      <FormGroup
        label={
          <div className={styles.passwordLabel}>
            {intl.formatMessage(messages.createPassword)}
            <PasswordPopup />
          </div>
        }
        error={errors.password?.message}
      >
        <TextInput
          type="password"
          placeholder={intl.formatMessage(messages.createPassword)}
          autoComplete="new-password"
          {...register('password')}
        />
      </FormGroup>

      <FormGroup
        label={intl.formatMessage(messages.confirmPassword)}
        error={errors.confirmPassword?.message}
      >
        <TextInput
          type="password"
          placeholder={intl.formatMessage(messages.confirmPassword)}
          autoComplete="new-password"
          {...register('confirmPassword')}
        />
      </FormGroup>

      <TrackedButton
        type="submit"
        label={intl.formatMessage(messages.submit)}
        disabled={isSubmitting}
        variant="secondary"
        trackLabel="submit-create-password"
      />
    </form>
  );
}
