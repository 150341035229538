import { PatientCoachIrisSummary } from '@allurion/domain';
import { ONE_HOUR_IN_MS } from '@allurion/utils';
import useSWR from 'swr';

import { buildUrl, InsightsApi } from 'src/api/Api';

export const COACH_IRIS_SUMMARY = (patientId: string) =>
  `/patients/${patientId}/coach-iris-summary`;

export function useCoachIrisSummary(patientId?: string, locale: string = 'en') {
  const { data, error, isValidating } = useSWR(
    patientId ? buildUrl(COACH_IRIS_SUMMARY(patientId), { 'locale': locale }) : null,
    (url: string) => {
      return InsightsApi.get<{ data: PatientCoachIrisSummary }>(url).then(({ data }) => data);
    },
    {
      revalidateOnFocus: false,
      suspense: true,
      dedupingInterval: ONE_HOUR_IN_MS,
    }
  );

  return {
    summary: data,
    error,
    isValidating,
  };
}
