import { FormGroup, TextInput } from '@allurion/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { z } from 'zod';

import { TrackedButton } from 'src/analytics/TrackedUI';
import { MyLink as Link } from 'src/components/ui/Link';
import globalMessages from 'src/messages/global.messages';
import * as appRoutes from 'src/utils/appRoutes';

import messages from './login.messages';

type FormValues = {
  email: string;
  password: string;
};

type Props = {
  initialValues: FormValues;
  onSubmit: (values: FormValues) => void;
};

export function LoginForm({ initialValues, onSubmit }: Props) {
  const intl = useIntl();

  const LoginFormSchema = z.object({
    email: z
      .string()
      .nonempty(intl.formatMessage(globalMessages.required))
      .email(intl.formatMessage(globalMessages.notValidEmail)),
    password: z.string().nonempty(intl.formatMessage(globalMessages.required)),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: zodResolver(LoginFormSchema),
    defaultValues: initialValues,
  });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup label={intl.formatMessage(messages.email)} error={errors.email?.message}>
        <TextInput
          type="email"
          placeholder={intl.formatMessage(messages.email)}
          {...register('email')}
        />
      </FormGroup>
      <FormGroup label={intl.formatMessage(messages.password)} error={errors.password?.message}>
        <TextInput
          type="password"
          placeholder={intl.formatMessage(messages.password)}
          {...register('password')}
        />
      </FormGroup>
      <Controls>
        <TrackedButton
          label={intl.formatMessage(messages.submitBtn)}
          type="submit"
          variant="secondary"
          trackLabel="submit-login-form"
        />
        <StyedLink to={appRoutes.resetPasswordRoute}>
          {intl.formatMessage(messages.resetPassword)}
        </StyedLink>
      </Controls>
    </Form>
  );
}

const Form = styled.form`
  max-width: 420px;
  margin: 0 auto;

  label {
    color: #fff;
    margin-bottom: 15px;
    font-size: 20px;
    display: inline-block;
    font-family: GT-America-Standard, sans-serif;
  }

  input {
    resize: none;
    width: 100%;
    font-family: GT-America-Standard, sans-serif;
    font-size: 16px;
    height: 100%;
    padding: 18px 14px;
    border-radius: 2px;
    border: none;
    outline: none;
    background-color: rgb(255, 255, 255) !important;
  }

  input + span {
    display: flex;
    -webkit-box-pack: start;
    justify-content: start;
    text-align: left;
    color: rgb(166, 101, 101);
    font-size: 16px;
    margin-top: 6px;
    margin-bottom: 12px;
    padding-left: 0px;
    transition:
      height 0.5s ease 0s,
      opacity 0.3s ease 0.2s;
    height: 0px;
    font-family: GT-America-Standard, sans-serif;
  }
`;

const Controls = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  margin-top: 15px;

  button {
    width: 100%;
  }
`;

const StyedLink = styled(Link)`
  color: #fff;
  font-family: 'GT-America-Standard-Light', sans-serif;
  border-bottom: 2px solid #fff;
  margin-top: 40px;
`;
