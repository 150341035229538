import { STAFF_ROLE_ID } from '@allurion/domain';
import { isError } from '@allurion/utils';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { useTrackEvent } from 'src/analytics/analytics';
import { updateProviderProfile, updateUserRole } from 'src/api/UserApi';
import { Loader } from 'src/components/ui/Loader';
import { toastError, toastSuccess } from 'src/components/ui/toasts';
import { ParsedClinicData } from 'src/domain/Clinic';
import { ProviderForm } from 'src/forms/User/ProviderForm';
import { useProvider } from 'src/hooks/useProvider';
import { useProviderRoleLabels } from 'src/hooks/useProviderRoleLabels';
import translations from 'src/pages/ManageUsers/ManageUsers.translations';
import { Logger } from 'src/services/Logger';

type Props = {
  onUpdate: (state: any) => void;
  goBack: () => void;
  clinics: ParsedClinicData[];
};

type FormValues = {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  clinics: string[];
};

export function EditProvider({ onUpdate, goBack, clinics }: Props) {
  const { providerId } = useParams();
  const intl = useIntl();
  const [isUpdating, setIsUpdating] = useState(false);
  const { provider, isLoading, reload } = useProvider(providerId);
  const { trackFormSuccess, trackFormError } = useTrackEvent();

  const providerClinics = (provider?.ClinicID ?? '').split(',');
  const roleID = provider?.RoleID;
  const providerRoles: { [key: string]: string } = useProviderRoleLabels();

  const initialValues = useMemo(
    () => ({
      firstName: provider?.FirstName ?? '',
      lastName: provider?.LastName ?? '',
      email: provider?.EmailAddress ?? '',
      role: roleID ?? STAFF_ROLE_ID,
      clinics: providerClinics,
    }),
    [provider?.EmailAddress, provider?.FirstName, provider?.LastName, providerClinics, roleID]
  );

  const onSubmit = (values: any) => {
    updateProvider(values);
  };

  const updateProvider = async (values: FormValues) => {
    if (!providerId) {
      return;
    }

    setIsUpdating(true);
    const clinicLabel = values.clinics
      ?.map((id) => clinics.find((clinic) => clinic.id === id)?.label)
      .join(', ');

    try {
      await updateProviderProfile(
        providerId,
        values.firstName,
        values.lastName,
        provider?.UnitsPreference ?? 'kg'
      );
      if (provider?.CognitoUserName) {
        await updateUserRole(providerId, values.role, values.clinics?.join());
      }
      setIsUpdating(false);
      toastSuccess(intl.formatMessage(translations.userFormWrapperSuccessEdit));
      trackFormSuccess('edit-provider');
      onUpdate({
        formEmail: provider?.EmailAddress,
        formFirstName: values.firstName,
        formLastName: values.lastName,
        clinicLabel,
        roleLabel: providerRoles[values.role],
      });
      reload();
    } catch (error) {
      const errorMessage = isError(error) ? error.message : error;

      Logger.captureException(error);
      setIsUpdating(false);
      toastError(intl.formatMessage(translations.userFormWrapperEditUserFailure));
      trackFormError('edit-provider', { error: errorMessage });
    }
  };

  return (
    <>
      <ProviderForm
        title={intl.formatMessage(translations.userFormWrapperEditUser)}
        goBack={goBack}
        isEditForm
        isLoading={isLoading}
        initialValues={initialValues}
        onSubmit={onSubmit}
        allClinics={clinics!}
        providerClinics={providerClinics}
      />
      <Loader isLoading={isUpdating} />
    </>
  );
}
