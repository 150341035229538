export function getJwtTokenExpirationDate(token: string) {
  const tokenPayload = token.split('.')[1];
  const expiryInSeconds = JSON.parse(atob(tokenPayload || '')).exp;
  const expiryInMiliseconds = expiryInSeconds * 1000;

  const expirationDate = new Date();

  expirationDate.setTime(expiryInMiliseconds);

  return expirationDate;
}

export function isJwtTokenExpired(token: string) {
  const expirationDate = getJwtTokenExpirationDate(token);

  return new Date() >= expirationDate;
}
