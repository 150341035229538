import { dateAdd, toDateStr } from '@allurion/utils';

export type PatientTreatment = {
  //deprecated fields
  TreatmentUserID: number;
  TreatmentTypeID: number;
  StartDate: string;
  EndDate: string | undefined;
  TreatmentTypeDefinition: string;
  CustomTreatmentID: number;
  //end of deprecated fields
  treatmentId: string;
  treatmentName: string;
  treatmentShortName: string;
  startDate: string;
  endDate: string | undefined;
  treatmentCategoryId: number;
  treatmentSubcategoryId: number;
  treatmentSubcategoryName: string;
};

export function isValidStartDate(startDateStr: string) {
  const today = new Date();
  const maxDate = toDateStr(dateAdd(today, 90));

  return startDateStr <= maxDate;
}

export function hasAllurionBalloonTreatment(treatments: PatientTreatment[]) {
  return treatments.find(({ TreatmentTypeID }) => isAllurionBalloonTreatment(TreatmentTypeID));
}

export function isAllurionBalloonTreatment(treatmentCategoryId?: number | string) {
  return treatmentCategoryId?.toString() === '1';
}
