import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { parseClinicsData } from 'src/domain/Clinic';
import { useAppNavigate } from 'src/hooks/useAppNavigate';
import { useManageClinicsProviders } from 'src/hooks/useManageClinicsProviders';
import { useUserClinics } from 'src/hooks/useUserClinics';

import { EditProvider } from '../ManageUsers/EditProvider';
import { ActionComplete } from '../ManageUsers/Form/ActionComplete';

export function EditProviderPage() {
  const [step, setStep] = useState<'form' | 'updated'>('form');
  const [actionCompleteState, setActionCompleteState] = useState<any>(null);
  const { toPreviousPage, toClinicProvidersPage } = useAppNavigate();
  const { clinics } = useUserClinics();
  const parsedClinics = parseClinicsData(clinics);
  const { clinicId } = useParams();
  const { reload } = useManageClinicsProviders(parsedClinics);

  const steps = {
    'form': <EditProvider clinics={parsedClinics} onUpdate={onUpdate} goBack={toPreviousPage} />,
    'updated': (
      <ActionComplete
        isUpdatedUser
        isDeletedUser={false}
        state={actionCompleteState}
        goBack={() => toClinicProvidersPage(clinicId!)}
      />
    ),
  };

  return <>{steps[step]}</>;

  function onUpdate(state: any) {
    setStep('updated');
    setActionCompleteState(state);
    reload();
  }
}
