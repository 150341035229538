import { isError } from '@allurion/utils';
import { captureException } from '@sentry/react';
import { useEffect, useRef } from 'react';

import { toastError } from 'src/components/ui/toasts';

import { QRCodeReader } from './QRCodeReader';

export type ParsedBalloonQRCode = {
  manufacturingLotNumber: string;
  manufacturingDate: string;
  expirationDate: string;
  serialNumber: string;
};

type Props = {
  onValid: (result: ParsedBalloonQRCode) => Promise<void>;
};

export function ScanBalloonQRCode({ onValid }: Props) {
  const containerRef = useRef<HTMLDivElement>(null);
  const containerId = 'reader';

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }

    const timeout = setTimeout(async () => {
      try {
        await QRCodeReader.init(containerId);
        await QRCodeReader.start({ parse: parseQrCode, onValid });
      } catch (error) {
        captureException(error);
        toastError(isError(error) ? error.message : error);
      }
    }, 0);

    return () => {
      clearTimeout(timeout);
    };
  }, [onValid]);

  return <div ref={containerRef} id={containerId} style={{ width: '400px' }}></div>;
}

function parseQrCode(decodedText: string): ParsedBalloonQRCode | false {
  // Define the Group Separator (GS) character
  const GS = '\u001D';

  // Split the decodedText using the GS character and filter out empty strings
  const parts = decodedText.split(GS).filter((part) => part !== '');

  // Check if the QR code has the expected number of parts
  if (parts.length !== 5) {
    return false;
  }

  const [, /*gtin*/ manufacturingLotNumber, _manufacturingDate, _expirationDate, serialNumber] =
    parts.map((part) => part.substring(2));

  const [manufacturingDate, expirationDate] = [_manufacturingDate, _expirationDate].map(
    (date) => `20${date.substring(0, 2)}-${date.substring(2, 4)}-${date.substring(4, 6)}`
  );

  return {
    manufacturingLotNumber,
    manufacturingDate,
    expirationDate,
    serialNumber,
  };
}
