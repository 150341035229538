import { ADMIN_ROLE_ID, STAFF_ROLE_ID } from '@allurion/domain';
import { useIntl } from 'react-intl';

import translations from 'src/pages/ManageUsers/ManageUsers.translations';

export function useProviderRoleLabels() {
  const intl = useIntl();

  // const superUserLabel = 'Super User';
  const adminLabel = intl.formatMessage(translations.manageUsersAdmin);
  const staffLabel = intl.formatMessage(translations.manageUsersStaff);
  // const coachLabel = intl.formatMessage(translations.manageUsersCoach);

  return {
    // [SUPER_ADMIN_ROLE_ID]: superUserLabel,
    [ADMIN_ROLE_ID]: adminLabel,
    [STAFF_ROLE_ID]: staffLabel,
    // [COACH_ROLE_ID]: coachLabel,
  };
}
